import React, { useState, CSSProperties, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../store/actions";

interface SubMenuItemProps {
  label: string;
  link: string;
}

interface MenuItemProps {
  label: string;
  link: string;
  newsReducer?: any;
  submenuItems?: SubMenuItemProps[];
}

const MenuItem: React.FC<MenuItemProps> = ({ label, link, newsReducer, submenuItems }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [active, setActive] = useState(false);

  const handleMouseEnter = () => {
    console.log("Hovering over menu item:", label); // Debugging
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    console.log("Mouse left menu item:", label); // Debugging
    setIsHovered(false);
  };

  const handleSetActive = () => {
    setActive(true);
    const allMenuItems = document.querySelectorAll(".menu-item");
    allMenuItems.forEach((item: any) => {
      if (item !== link) {
        localStorage.setItem(item, JSON.stringify(false));
      }
    });
    localStorage.setItem(link, JSON.stringify(true));
  };

  useEffect(() => {
    const savedActiveState = localStorage.getItem(link);
    if (savedActiveState) {
      setActive(JSON.parse(savedActiveState));
    }
  }, [link]);

  const menuItemStyle: CSSProperties = {
    margin: "0 10px",
    textDecoration: "none",
    padding: "10px",
    cursor: "pointer",
    fontSize: "13px",
    color: isHovered ? "#1B48E0" : newsReducer.mode === "light" ? "#000000" : "#ffffff",
    fontWeight: 400,
    transition: "color 0.3s ease",
  };

  const submenuStyle: CSSProperties = {
    display: isHovered ? "block" : "none",
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: newsReducer.mode === "light" ? "#ffffff" : "#333333",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    padding: "10px",
    zIndex: 1000,
  };

  return (
    <div
      className="menu-item-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <Link to={link} onClick={handleSetActive} style={menuItemStyle}>
        {label}
      </Link>

      {submenuItems && submenuItems.length > 0 && (
        <div className="submenu" style={submenuStyle}>
          {submenuItems.map((item, index) => (
            <Link
              to={item.link}
              key={index}
              className="submenu-item"
              style={{
                display: "block",
                padding: "8px 10px",
                color: newsReducer.mode === "light" ? "#333" : "#ffffff",
              }}
            >
              {item.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(MenuItem);
